import React from "react";
import { Helmet, useTranslation } from "gatsby-plugin-react-i18next";

import "./_projects.scss";
import { ProjectInfoCard } from "./ProjectInfoCard";
import { useProjectInfosLoader } from "./dataLoader";

import { DefaultLayout } from "../../layouts/DefaultLayout";

export const ProjectsPage: React.FC = () => {
  const { t } = useTranslation();
  const projectInfosMap = useProjectInfosLoader();
  const projectsByCreated = Array
    .from(projectInfosMap)
    .sort((lhs, rhs) => rhs[1].createdAt.getTime() - lhs[1].createdAt.getTime())
    .map(([projectId, projectInfo]) => <ProjectInfoCard project={projectInfo} key={projectId}/>);

  return (
    <DefaultLayout>
      <Helmet>
        <title>{t("nav.projects")} | {t("company.name")}</title>
      </Helmet>
      <div className="bg-gray-8">
        <div className="container mx-auto py-10">
          <h1 className="text-blue-3 font-semibold font-display text-6xl text-center">Dự án</h1>
          <h2 className="font-medium text-center text-gray-1 text-lg">VSJ đã và đang tham gia vào nhiều dự án quan trọng
            với quy mô lớn</h2>
          <div className="flex flex-wrap -mx-4 mt-8">
            {projectsByCreated}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
