import React from "react";
import classNames from "classnames";

import { Tag } from "../../../../models";
import { ClassNameProps } from "../../../../common/props";
import { Icon } from "../../../core/icon/Icon";
import { IconName } from "../../../core/icon/iconName";

export type ProjectTagListProps = ClassNameProps & {
  tags: Tag[];
};

export const ProjectTagList: React.FC<ProjectTagListProps> = ({
  tags,
  className,
}: ProjectTagListProps) => {
  if (!tags || tags.length === 0) {
    return null;
  }

  const tagWithSeps = tags.map((tag, index) => [
    <span key={`__tag.${index}`}>{tag.name}</span>,
    <Icon key={`__sep.${index}`} className="w-3 h-3 mx-1 inline" icon={IconName.Dot}/>,
  ]).flat();

  tagWithSeps.pop();

  return (
    <span className={classNames(className, "text-sm text-gray-3 font-medium")}>
      {tagWithSeps}
    </span>
  );
};
