import { useI18next } from "gatsby-plugin-react-i18next";
import { graphql, useStaticQuery } from "gatsby";

import { Project } from "../../../../models";

export function useProjectInfosLoader(): Map<string, Project> {
  const { language } = useI18next();

  const data = useStaticQuery(graphql`
    query {
      allContentfulProject {
        edges {
          node {
            id
            name
            createdAt
            featureImage {
              title
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            description {
              description
            }
            node_locale
            tags {
              name
            }
          }
        }
      }
    }
  `);

  const projects = new Map<string, Project>();

  for (const node of data.allContentfulProject.edges) {
    const nodeData = node.node;

    if (nodeData.node_locale !== language) {
      continue;
    }

    const project: Project = {
      name: nodeData.name,
      description: nodeData.description.description,
      featureImage: {
        title: nodeData.featureImage.title,
        url: nodeData.featureImage.localFile.publicURL,
        fluid: nodeData.featureImage.localFile.childImageSharp.fluid,
      },
      albumImages: [],
      tags: nodeData.tags,
      createdAt: new Date(nodeData.createdAt),
      updatedAt: new Date(),
    };

    projects.set(nodeData.id, project);
  }

  return projects;
}
