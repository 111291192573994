import React from "react";
import classNames from "classnames";
import GatsbyImage from "gatsby-image";
import { Link } from "gatsby-plugin-react-i18next";

import { ProjectTagList } from "./ProjectTagList";

import { Project } from "../../../../models";
import { ClassNameProps } from "../../../../common/props";

type ProjectInfoCardProps = ClassNameProps & {
  project: Project;
};

export const ProjectInfoCard: React.FC<ProjectInfoCardProps> = ({
  project,
  className,
}: ProjectInfoCardProps) => {
  return (
    <div className={classNames(className, "flex p-4 w-1/3")}>
      <Link
        to="/"
        className="w-full flex flex-col bg-white rounded-lg shadow-lg text-gray-1 transition duration-500 transform hover:-translate-y-4 hover:scale-105 hover:shadow-2xl">
        <span className="h-64">
          <GatsbyImage
            fluid={project.featureImage.fluid}
            alt={project.featureImage.title}
            className="h-full rounded-t-lg"
          />
        </span>
        <span className="p-6 flex flex-col">
          <span className="font-semibold text-lg">{project.name}</span>
          <ProjectTagList tags={project.tags}/>
          <span className="mt-3 project-info-card-description">{project.description}</span>
        </span>
      </Link>
    </div>
  );
};
